import { inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { onLocaleChangeInitPlugins } from '@app/../_config';

export interface Locale {
  lang: string;
  data: any;
}

export const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class LocaleService {
  private translate: TranslateService = inject(TranslateService);
  private destroyer$: Subject<any> = new Subject<any>();

  private initialized = false;
  private langIds: any = [];

  constructor(
    @Optional()
    @SkipSelf()
      otherInstance: LocaleService,
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';
  }

  get currentLocale(): string {
    return (
      localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) ||
      this.translate.currentLang
    );
  }

  /**
   * Initialize the location in the app, for this it takes into account whether the location has not been previously initialized.
   * It then subscribes to language changes using this.translate.onLangChange, After subscription,
   * it sets the default language and the specific language, and marks the initialization as completed.
   *
   * Note that if you invoke this method you must call "destroySubscription()" when necessary to avoid memory leaks.
   *
   * @param localeID
   * @param defaultLocaleID
   */
  initLocale(localeID: string | null = null, defaultLocaleID = 'es') {
    if (this.initialized) return;

    if (null === localeID) {
      localeID = this.currentLocale ?? defaultLocaleID;
    }

    this.translate.onLangChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(event => {
        this.initPlugins(event.lang)
      })

    this.setDefaultLocale(defaultLocaleID);
    this.setLocale(localeID);

    this.initialized = true;
  }

  setDefaultLocale(localeID: string) {
    this.translate.setDefaultLang(localeID);
  }

  setLocale(localeID: string) {
    localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, localeID);
    this.translate.use(localeID);
  }

  loadLocalesTranslations(...args: Locale[]): void {
    [...args].forEach((locale) => {
      this.loadLocaleTranslation(locale);
    });

    this.translate.langs = [...new Set(this.translate.langs)];
  }

  loadLocaleTranslation(locale: Locale) {
    if (!this.translate.langs.includes(locale.lang)) {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);

      this.translate.langs.push(locale.lang)
    }
  }

  // lazyLoadLocaleTranslationFromLangID(localeID: string) {
  //   const vocabs = I18N_AVAILABLE_VOCABS.filter((vocab: ILangVocab) => vocab.lang === localeID);
  //   this.loadLocalesTranslations(...vocabs)
  // }

  private initPlugins(localeID: string) {
    onLocaleChangeInitPlugins(localeID);
  }

  destroySubscription() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }

}
